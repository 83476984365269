import { marketoService } from "./marketo-service";
import { storageService } from "./storage-service";

export function getReferralRockEmail(form:FormData, rrCode): Promise<string> {
    const options = {
        method: 'POST',
        body: JSON.stringify({ 
            referralCode: rrCode,
            firstName: form.get('firstName'),
            lastName: form.get('lastName'),
            email: form.get('email'),
            companyName: form.get('name')
        })
    } ;
    return fetch('/api/referralrock/addReferral', options)
            .then(res => res.json())
            .then(data => data.referral.memberEmail)
            .catch(err => { 
              console.error(err);
              return Promise.resolve('');
            });
}

  /*
    - Called only at Console Form when user select ONLY 'Refer clients to BILL and earn rewards'
    - Do not submit to SFDC
  */
export function submitToReferralRock(form: HTMLFormElement):Promise<string | undefined> {
    const formData = new FormData(form);
    let data = {
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        email: formData.get('email'),
        programId: 'ef4cda0b-1ae4-4394-a2f0-89121344561d'
    } as any; 
    return fetch('/api/referralrock/submitForm', {
        method: 'POST',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(obj => {
        if(!obj?.member) return obj?.message || 'Unexpected response from ReferralRock.';
        const referralUrl = obj.member.referralUrl;
        storageService.setReferralRockReferralUrl(referralUrl);
        // extra marketo form form fields
        const extra = {
            Accountant_Referral_Link__c: referralUrl,
            Opted_into_referral_rewards__c: 'yes'
        }

        return marketoService.loadForm(1253)
            .then(mktoForm => mktoForm.submit(form, extra))
            .then(() => window.location.href = '/referral-confirmation');
    }).catch(e => {
        console.error(e);
        if(e.error.message?.includes('Email address is already being used')) {
            return 'Email address is already being used. To access your account, click <a href="https://billcom.referralrock.com/v2/1/login" traget="_blank">here</a>.';
        }
        else {
            return 'There was an error. Pleaase try again.';
        }
    })
}
class CookieService {
    getGaClientId() {
        const val = this.valueOf('_ga');
        return val && val.substring(6);
    }

    getOneLogin = () => this.valueOf('ol');

    setSubmitDate(date?: Date) {
        const isoString = (date || new Date()).toISOString();
        document.cookie =  `mktSubmit=${isoString}; Domain=.bill.com; Path=/`;
    }

    private valueOf(name) {
        const cookies = ' ' + document.cookie + ';';
        const cookieName = ' ' + name + '=';
        const ix = cookies.indexOf(cookieName);
        if(ix === -1) return null;
        const valueIx = ix + cookieName.length;
        return cookies.slice(valueIx, cookies.indexOf(';', valueIx));
    }
}


export const cookieService = new CookieService();
import { storageService } from "./storage-service";

export function configureLoginButton() {
    const clicked = storageService.getClickedLogin();
    if(clicked) return;
    document.querySelectorAll<HTMLElement>('.button-login').forEach(btn => {
        btn.addEventListener('click', _ => {
            storageService.setClickedLogin(true);
        });
    });
}
class ScriptLoaderService {
    private loadedScripts = new Map<string, Promise<any>>();

    loadScript(url:string, options: Partial<{async: boolean, defer: boolean, oneTrustIgnore: boolean, class: string}> = {async: true}): Promise<any> {
        let result = this.loadedScripts.get(url);
        if(!result) {
          result = new Promise<any>((resolve, reject) => {
            const s = document.createElement('script');
            s.onerror = s.onabort = () => reject('failed to load marketo script');
            s.onload = resolve;
            s.type = 'text/javascript';
            s.async = Boolean(options.async); // set to true by default
            s.defer = Boolean(options.defer);
            // prevent onetrust blocking script
            if(options.oneTrustIgnore) {
              s.setAttributeNode(document.createAttribute('data-ot-ignore'));
            }
            if(options.class) {
              s.setAttribute('class', options.class);
            }
            s.src = url;
            document.getElementsByTagName('head')[0].appendChild(s);
          });
          this.loadedScripts.set(url, result);
        }
    
        return result;
    }
}

export const scriptLoader = new ScriptLoaderService();
import { scriptLoader } from "./script-loader";

class ThreatMetrixService {
    sessionId:Promise<string> | undefined;
    registerDevice():Promise<string> {
        let newSessionId = this.newSessionId();
        this.sessionId = scriptLoader.loadScript('https://tm.bdc-cdn.com/fp/tags.js?org_id=ceurt9zj&session_id=' + newSessionId).then(() => newSessionId);
        return this.sessionId;
    }

    isDeviceRegistered = () => Boolean(this.sessionId);

    private newSessionId() {
        let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let len = chars.length;
        // fill spaces with random chars above
        return ' '.repeat(32).replaceAll(' ', () => chars.charAt(Math.random()*len|0));
    }
}

export const threatmetrixService = new ThreatMetrixService();

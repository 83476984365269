class ComponentService {
    configureAll() {
        this.configureToggle();
    }

    private configureToggle() {
        document.querySelectorAll<HTMLAnchorElement>('a[data-toggle][data-target]').forEach(btn => {
            btn.addEventListener('click', (ev:MouseEvent) => {
                const needsValidation = (ev.target as HTMLAnchorElement)?.dataset?.toggleValidate;
                if(needsValidation) {
                    const [func, ...params] = needsValidation.split('|');
                    const valid = validators[func]?.apply(null, params);
                    if(!valid) return false;
                }
                document.querySelectorAll(btn.dataset.target!).forEach(el => {
                    el.classList.toggle(btn.dataset.toggle!);
                });
            });
        });
    }
}

const validators = {
    showInvalid: function (invalidSelector: string) {
        // show first invalid element
        const el = document.querySelector(invalidSelector);
        if(el && 'reportValidity' in el) (el as any).reportValidity();
        return !el;
    },
    ifNone: function(selector: string, noneMessageSelector: string) {
        // report valid if there is at least one element;
        const valid = Boolean(document.querySelector(selector));
        document.querySelector(noneMessageSelector)?.classList.toggle('hide', valid);
        return valid;
    }
}

export const componentService = new ComponentService();
class PartnerStackService {
    submitConverstion(email: string, clickId: string) : Promise<string> {
        return fetch('/api/partnerstack/conversion', {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({
                xid: clickId,
                email
            })
        }).then(r => !r.ok 
            ? r.text().then(t => Promise.reject(t)) 
            : r.json().then(d => d.customerKey)
        );
    }
}

export const partnerStackService = new PartnerStackService()
//import { captchaService } from "./captcha-service";
import { cookieService } from "./cookie-service";
import { environment } from "./environment";
import { configureInputs, populateFromStorage, getProductInterestOrNull, toggleProductInterestError, getABTestVariances, disableSubmitButton, saveFormData, MKTO_FIELD_PARTNERSTACK_KEY} from "./form-service";
import { loggerService } from "./logger-service";
import { partnerStackService } from "./partnerstack-service";
import { scriptLoader } from "./script-loader";
import { storageService } from "./storage-service";


const munchkinId = environment.munchkinId;
const marketoBaseUrl = `https://${munchkinId}.mktoweb.com`;
const marketoFormsUrl = `${marketoBaseUrl}/js/forms2/js/forms2.min.js`;
export const defaultForm = 1557;
declare const MktoForms2: any;

class MarketoService {

    setupForms() {
        document.querySelectorAll<HTMLFormElement>('form[data-mkto-form]').forEach(form => {
            const formId = form.dataset.mktoForm || defaultForm;

            form.onsubmit = (ev:SubmitEvent) => {
                ev.preventDefault();
                ev.stopPropagation();

                disableSubmitButton(ev.submitter);

                this.loadForm(formId)
                    .then(mkto => mkto.submit(form))
                    .then(() => {
                        if(form.dataset.redirect?.length) window.location.href = form.dataset.redirect;
                        else window.location.reload();
                        return false;
                    }); 
            }
            configureInputs(form);
            populateFromStorage(form);
            this.loadForm(formId); //preload marketo form
        });
    }

    loadForm(formId: number | string = defaultForm) : Promise<MarketoForm> {
        return scriptLoader.loadScript(marketoFormsUrl).then(() => 
            new Promise((resolve, reject) => {
                MktoForms2.loadForm(marketoBaseUrl, munchkinId, formId, (form:any) => {
                    // on error the callback will be called with null param
                    return form ? resolve(new MarketoForm(form)) : reject('failed to load marketo form');
                });
            })
        );
    }

    getAccountingSoftware = getAccountingSoftware;
}

// wrapper for marketo form object
export class MarketoForm {
    private form: IMarketoForm;
    constructor(form: IMarketoForm) {
        this.form = form;
    }

    submit(form: HTMLFormElement, extra?: object): Promise<MarketoForm> {      
        const formData = new FormData(form);
        let mktoData = {} as any;
        try { saveFormData(form); } catch (e) { console.error(e); }

        // get marketo field names from each html element
        form.querySelectorAll<HTMLInputElement|HTMLSelectElement>('[data-mkto-field]').forEach(el => {
            const mktoFieldName = el.dataset.mktoField;
            const fieldValue = formData.get(el.name);
            if (mktoFieldName && fieldValue) mktoData[mktoFieldName] = fieldValue;
        });
        // special case for fields that don't exist in marketo, but should be merged instead into a single field
        form.querySelectorAll<HTMLInputElement|HTMLSelectElement>('[data-mkto-field-merge]').forEach(el => {
            const mergeToField = el.dataset.mktoFieldMerge;
            const fieldValue = formData.get(el.name);
            if (!mergeToField || !fieldValue) return;
            mktoData[mergeToField] ||= '';
            mktoData[mergeToField] += `${el.name}=${fieldValue};`;
        });
        
        if(!setNonEmptyProductInterest(form, mktoData)) {
            toggleProductInterestError(form, true);
            return Promise.reject();
        }
        updateMarketoValues(formData, mktoData);
        // add offer id which should be set by the author of the page/form or may be set in URL (and stored)
        const offerId = form.dataset.offerId || storageService.getOfferId();
        if(offerId) mktoData.op_lt_offerid = offerId;
        
        const abVals = getABTestVariances();
        if (abVals.length) mktoData['AB_Test_2_Lead__c'] = abVals.trim();
        
        // console.log('mkto submit', mktoData);
        return new Promise((resolve, reject) => {
            this.form.onSuccess(form => {
                resolve(new MarketoForm(form));
                return false;
            });
            this.form.addHiddenFields(mktoData);
            this.form.addHiddenFields(getMarketoUtms());
            if(extra) this.form.addHiddenFields(extra);
            // captchaService.getToken().then(token => {
            //     this.form.setCaptchaValue(token);
            //     this.form.submit();
            // });
            this.tryPartnerStack(mktoData.Email, this.form)
                .then(() => this.form.submit());
            // fail after 5 sec
            setTimeout(() => reject('submit didn\'t respond after 5s'), 5000);
        });
    }

    private tryPartnerStack(email: string, form: IMarketoForm):Promise<void> {
        const partnerStackClickId = storageService.getPartnerStackClickId();
        if (!partnerStackClickId) return Promise.resolve();
        return partnerStackService
            .submitConverstion(email, partnerStackClickId)
            .then(key => form.addHiddenFields({[MKTO_FIELD_PARTNERSTACK_KEY]: key }))
            .catch(e => {
                let err = 'PartnerStack Error: ' + e.toString();
                console.error(err);
                loggerService.logError(e);
            });
    }
}

function setNonEmptyProductInterest(form: HTMLFormElement, mktoData: any):boolean {
    const productInterest = getProductInterestOrNull(form);
    if(productInterest?.length){
        const isUP = productInterest.length > 2 && productInterest.includes('SE');
        mktoData['op_lt_product_interest'] = isUP ? 'UP' : productInterest;
    };
    return productInterest !== ''; //empty (non-null) product interest means no selection
}

function getAccountingSoftware(form: FormData): [id: string, name: string] | null {
    const softwareField = 'accountingSoftware';
    let softwareId = form.get(softwareField) as string;
    if (!softwareId) return null;
    if (softwareId === 'msdynamics') softwareId = form.get('msDynamicsOption') as string;
    
    const softwareNames = {
        qboe2: 'QuickBooks Online',
        qbd: 'QuickBooks Pro/Premier',
        qbe: 'QuickBooks Enterprise',
        xero2: 'Xero',
        netsuite2: 'NetSuite',
        intacct2: 'Intacct',
        // msdynamics: 'MS Dynamics',
        acumatica: 'Acumatica',
        blackbaud: 'Blackbaud',
        sap: 'SAP',
        sageplus: 'Sage (200/300/ERP)',
        freshbooks: 'Freshbooks',
        qbmac: 'QuickBooks for Mac',
        quicken: 'Quicken',
        sage100: 'Sage 100',
        sage50: 'Sage 50',
        reuters: 'Thomson Reuters',
        yardi: 'Yardi',
        other: 'Other',
        none: 'None',
        unknown: 'I don\'t know',
        msdynamicsgp: 'MS Dynamics GP',
        msdynamicsbizcentral: 'MS Dynamics 365 Business Central',
        msdynamics: 'MS Dynamics (AX, NAV, SL, 365 F&O)',
    };
    return [softwareId, softwareNames[softwareId]];
}

function updateMarketoValues(form: FormData, mktoData: any) {
    if (form.get('formoptions') === 'console') {
        mktoData['Type__c'] = 'Accounting';
    }
    if (form.get('firmtype') === 'wmanagement') {
        mktoData['Firm_Type__c'] = 'Wealth Management'; 
    }
    const [_, softwareName] = getAccountingSoftware(form) || [];
    if(softwareName) mktoData['Accounting_Package__c'] = softwareName;
}

// Native marketo interface
interface IMarketoForm {
    submit(): IMarketoForm
    addHiddenFields(values: object);
    setCaptchaValue(token: string);
    onSuccess(callback: (form: IMarketoForm) => boolean): IMarketoForm;
}

function getMarketoUtms() {
    // marketo doesn't like explicit nulls
    let result:any = {};
    const previousPage = storageService.getPreviousPage();
    const ft = storageService.getFirstTouchValues();
    result.op_ft_timestamp = ft.timestamp;
    if(ft.utmMedium) result.op_ft_utm_medium = ft.utmMedium;
    if(ft.utmSource) result.op_ft_utm_source = ft.utmSource;
    if(ft.utmCampaign) result.op_ft_utm_campaign = ft.utmCampaign;
    if(ft.utmContent) result.op_ft_utm_content = ft.utmContent;
    if(ft.utmTerm) result.op_ft_utm_term = ft.utmTerm;
    if(ft.ppcPlacement) result.op_ft_placement = ft.ppcPlacement;
    if(ft.referrer) result.op_ft_referrer = truncateUrl(ft.referrer);
    if(ft.device) result.op_ft_device = ft.device;
    if(ft.platformId) result.op_ft_platformid = ft.platformId;
    if(ft.affiliateId) result.op_ft_affiliateid = ft.affiliateId;
    if(ft.channelId) result.op_ft_channelid = ft.channelId;
    if(ft.landingPage) result.op_ft_landing_page = truncateUrl(ft.landingPage);
    if(storageService.getIsFirstTouchSession()) {
      if(previousPage) result.op_ft_converting_page_path = truncateUrl(previousPage);
      result.op_ft_converting_page = truncateUrl(window.location.href);
    }
    
    const lt = storageService.getLastTouchValues();
    if(lt) {
        result.op_lt_timestamp = new Date().toISOString();
        if(lt.utmMedium) result.op_lt_utm_medium = lt.utmMedium;
        if(lt.utmSource) result.op_lt_utm_source = lt.utmSource;
        if(lt.utmCampaign) result.op_lt_utm_campaign = lt.utmCampaign;
        if(lt.utmContent) result.op_lt_utm_content = lt.utmContent;
        if(lt.utmTerm) result.op_lt_utm_term = lt.utmTerm;
        if(lt.ppcPlacement) result.op_lt_placement = lt.ppcPlacement;
        if(lt.referrer) result.op_lt_referrer = truncateUrl(lt.referrer);
        if(lt.device) result.op_lt_device = lt.device;
        if(lt.platformId) result.op_lt_platformid = lt.platformId;
        if(lt.affiliateId) result.op_lt_affiliateid = lt.affiliateId;
        if(lt.channelId) result.op_lt_channelid = lt.channelId;
        if(lt.landingPage) result.op_lt_landing_page = truncateUrl(lt.landingPage);
        if(lt.pactId) result.PACT_ID__c = lt.pactId;
    }
    if(previousPage) result.op_lt_converting_page_path = truncateUrl(previousPage);
    result.op_lt_converting_page = truncateUrl(window.location.href);

    const clickIds = storageService.getClickIds() || {};
    if(clickIds.gclid) result["ft_GCLID__c"] = clickIds.gclid;
    if(clickIds.fbclid) result['ft_fbclid__c'] = clickIds.fbclid;
    if(clickIds.liclid) result['ft_liclid__c'] = clickIds.liclid;
    if(clickIds.msclkid) result['ft_msclkid__c'] = clickIds.msclkid;
    
    const clientId = cookieService.getGaClientId();
    if(clientId) result["ga_clientId__c"] = clientId;

    return result;
  }

  function truncateUrl(val: string): string {
    if(!val) return val;
    const url = val.split('?')[0];
    return url.substring(0, 255);
  }


export const marketoService = new MarketoService();

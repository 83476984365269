import { environment } from "./environment";

class BrInviteService {
    showDialogOnEmailEntry(form: HTMLFormElement) {
        const emailField = form.elements.namedItem('email') as HTMLInputElement;
        if(!emailField) return;
        emailField.addEventListener('blur', _ => {
            const email = emailField.value;
            if (email?.length <= 7) return;

            const encodedData = encodeURIComponent(JSON.stringify({ vendorEmail: email })); 

            fetch(environment.brInviteUrl + '?data=' + encodedData)
            .then(resp => resp.json())
            .then(data => {
                if(!data.response_data?.length) return;
                const dialog = document.getElementById('br-invite-dialog');
                if(!dialog) return;
                dialog.classList.remove('hide');
                const firstInvite = data.response_data[0];
                dialog.querySelectorAll('.dynamic-orgname').forEach(el => el.textContent = firstInvite.invitorOrgName);
                dialog.querySelectorAll<HTMLAnchorElement>('#br-invite-accept').forEach(el => el.href = firstInvite.signupUrl);
            })
            .catch(console.error);
        });
    }
}

export const brInviteService = new BrInviteService();
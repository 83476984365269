import { isConsoleForm } from "./form-service";

const suspects = [
    'cpa',
    'accounting',
    'bookkeep',
    'family office',
    'partners',
    'advisor',
    'consult',
    'financial',
    'wealth',
    'finance',
    'tax',
    'invest',
    'trust',
    'estate',
    'venture',
    'capital',
];

class SuspectedAcService {
    async confirmIsDirect(form: HTMLFormElement) : Promise<boolean> {
        if(isConsoleForm(form)) return false;

        const companyNameField = form.elements.namedItem('name') as HTMLInputElement;
        const companyName = companyNameField?.value.toLowerCase();
        if(companyName && !suspects.some(suspect => companyName.includes(suspect))) return true;

        const dialog = document.querySelector('#ac-flag-dialog.hide');
        if(!dialog) return true;
        dialog.classList.remove('hide');

        const keepValue = new Promise<boolean>((resolve) => {            
            dialog.querySelectorAll('[data-dialog-close]').forEach(btn => {
                btn.addEventListener('click', () => resolve(true));
            });
        });

        const changeValue = new Promise<boolean>((resolve) => {
            dialog.querySelectorAll('#proceed-console-signup').forEach(btn => {
                btn.addEventListener('click', () => {
                    form.dataset.offerId = '701Vw00000Aij0XIAR';
                    resolve(false);
                });
            });
        });

        return Promise.any([keepValue, changeValue]);
    }
}

export const suspectedAcService = new SuspectedAcService();
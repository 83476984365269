export function verifyUser(user: {email: string}): Promise<UserValidationResult> {
    return fetch('/api/user/verify', {
        method: 'POST',
        body: JSON.stringify(user)
    }).then(resp => resp.json().then(d => {
        d.success = resp.ok;
        return d;
    }));
}

export function getUserInfo(email: string): Promise<any> {
    return fetch('/api/user/info', {
        method: 'POST',
        body: JSON.stringify({email})
    }).then(resp => resp.json());
}

export interface UserValidationResult {
    success: boolean;
    errrors?: string[];
    isBusinessEmail?: boolean;
}